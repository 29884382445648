<template>
  <MatchMedia v-slot="{ md }">
    <div
      @mouseenter="likeComponentVisible = true"
      @mouseleave="likeComponentVisible = true"
      class="tw-relative"
      :class="{ expanded: expandStoryCarouselEnabled }"
    >
      <StoryCarousel
        :default-duration="5"
        :active="active"
        :isMobile="isMobile"
        :durations="durations"
        @slideChanged="slideChanged"
        ref="carousel"
        @click="e => $emit('click', e)"
        class="story"
        :expandStoryCarouselEnabled="expandStoryCarouselEnabled"
      >
        <template v-for="(item, i) in items">
          <rl-carousel-slide
            :key="item.url"
            class="slide w-100 h-100 position-relative tw--top-10"
            :class="{
              'slide-invisible':
                slide !== i && slide !== i + 1 && slide !== i - 1,
              'slide-next': slide === i + 1,
              'slide-prev': slide === i - 1,
            }"
          >
            <template v-if="slide === i + 1 || slide === i - 1 || slide === i">
              <div class="tw-top-20 tw-left-5 tw-relative tw-z-10">
                <MobileUserIcon
                  v-if="isMobile"
                  :bgImage="influencer.image"
                  :userName="influencer.influencer_id"
                  avaBorderColor="primary"
                  checked
                />
                <user-icon
                  v-if="!isMobile"
                  :bgImage="influencer.image"
                  :userName="influencer.influencer_id"
                  avaBorderColor="primary"
                  checked
                />
              </div>
            </template>
            <ImageSlide
              ref="slides"
              :visible="slide === i"
              v-if="
                item.type === 'image' &&
                ((slide === i + 1 && active) ||
                  (slide === i - 1 && active) ||
                  slide === i)
              "
              :item="item"
              :index="i"
              @click="
                event => {
                  if (expandStoryCarouselEnabled) {
                    if (slide === i + 1) {
                      $refs.carousel.nextSlide();
                    }
                    if (slide === i - 1) {
                      $refs.carousel.prevSlide();
                    }
                  }
                }
              "
            />
            <VideoSlide
              ref="slides"
              v-if="item.type === 'video'"
              :item="item"
              :index="i"
              :load="
                (slide === i + 1 && active) ||
                (slide === i - 1 && active) ||
                slide === i
              "
              :visible="slide === i"
              :forceMuted="forceMuted"
              @duration-changed="d => durationChanged(i, d)"
              @click="
                event => {
                  if (expandStoryCarouselEnabled) {
                    if (slide === i + 1) {
                      $refs.carousel.nextSlide();
                    }
                    if (slide === i - 1) {
                      $refs.carousel.prevSlide();
                    }
                  }
                }
              "
            />
          </rl-carousel-slide>
        </template>
      </StoryCarousel>

      <template v-if="!md && !isMobile">
        <div
          v-if="storyId && (likeComponentVisible || isLiked)"
          class="like-component"
        >
          <a @click="toggleLike" class="mobile-likes">
            <div class="like-icon" v-if="!isLiked">
              <LikeIcon width="20" />
            </div>
            <div class="like-icon" v-if="isLiked">
              <LikeFilledIcon width="20" />
            </div>
            {{ likeCount }} likes
          </a>
        </div>
      </template>

      <template v-else>
        <div
          class="
            volume-icon
            tw-bg-white tw-rounded-full tw-h-8 tw-w-8 tw-absolute
          "
          v-if="showButtons"
          @click="
            event => {
              event.stopPropagation();
              forceMuted = !forceMuted;
            }
          "
        >
          <MuteIcon v-if="forceMuted" />
          <UnmuteIcon v-else />
        </div>

        <div
          class="
            expand-icon
            tw-bg-white
            tw-rounded-full
            tw-h-8
            tw-w-8
            tw-pt-2
            tw-pb-2
            tw-absolute
          "
          v-if="showButtons"
          @click="
            event => {
              event.stopPropagation();
              $emit('toggle-expand');
            }
          "
        >
          <ExpandIcon />
        </div>
      </template>
    </div>
  </MatchMedia>
</template>

<script>
import StoryCarousel from './StoryCarousel';
import ImageSlide from './slides/ImageSlide.vue';
import VideoSlide from './slides/VideoSlide.vue';
import LikeIcon from '@/assets/inspirationPage/like.svg';
import LikeFilledIcon from '@/assets/inspirationPage/like-filled.svg';
import MuteIcon from '@/assets/inspirationPage/mute.svg';
import UnmuteIcon from '@/assets/inspirationPage/unmute.svg';
import ExpandIcon from '@/assets/inspirationPage/expand.svg';
import { RlCarouselSlide } from 'vue-renderless-carousel';
import { MatchMedia } from 'vue-component-media-queries';
import UserIcon from '@/views/landingPage/landingPageComponents/ExploreExperiences/UserIcon.vue';
import MobileUserIcon from '@/views/mobilePages/UserIcon.vue';

export default {
  name: 'Story',
  components: {
    VideoSlide,
    ImageSlide,
    StoryCarousel,
    LikeIcon,
    LikeFilledIcon,
    RlCarouselSlide,
    MatchMedia,
    MuteIcon,
    UnmuteIcon,
    ExpandIcon,
    MobileUserIcon,
    UserIcon,
  },
  props: {
    storyId: String,
    expandStoryCarouselEnabled: Boolean,
    showButtons: Boolean,
    isMobile: Boolean,
    active: Boolean,
    influencer: Object,
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: function() {
    return {
      likeComponentVisible: false,
      isLiked: false,
      likeCount: 0,
      slide: 0,
      forceMuted: true,
    };
  },
  computed: {
    durations() {
      const d = {};

      this.items.forEach((item, index) => {
        if (item.type === 'video' && item.duration) {
          d[index] = item.duration;
        }
      });

      return d;
    },
  },
  methods: {
    resetSlide() {
      this.$refs.carousel.resetSlide();
    },
    durationChanged(index, duration) {
      this.durations[index] = duration;
    },
    slideChanged(index) {
      this.slide = index;
      this.$refs.slides.forEach((slide, i) => {
        if (i === index) {
          if (typeof slide.show === 'function') {
            slide.show();
          }
        } else {
          if (typeof slide.hide === 'function') {
            slide.hide();
          }
        }
      });
    },
    toggleLike() {
      this.isLiked = !this.isLiked;

      if (this.isLiked) {
        this.likeCount++;
      } else {
        this.likeCount--;
      }
    },
  },
  mounted() {
    const likedStorys = JSON.parse(localStorage.getItem('likes'));
    if (likedStorys && likedStorys.includes(this.storyId)) {
      this.isLiked = true;
      this.likeCount++;
    }
  },
  watch: {
    isLiked(newValue) {
      const likedStorysEncoded = localStorage.getItem('likes') || '[]';
      const likedStorys = JSON.parse(likedStorysEncoded);
      const index = likedStorys.indexOf(this.storyId);
      if (index > -1 && !newValue) {
        likedStorys.splice(index, 1);
      } else if (index === -1 && newValue) {
        likedStorys.push(this.storyId);
      }

      localStorage.setItem('likes', JSON.stringify(likedStorys));
    },
  },
};
</script>

<style lang="scss" scoped>
.story {
  .slide {
    height: 100%;
    width: 100%;
    background-size: cover;
  }
}

.like-component {
  @apply tw-absolute tw-w-full tw-bottom-12 tw-left-6;

  a {
    font-family: 'MontserratSemiBold';
    @apply tw-font-bold tw-text-white;
  }
}

.mobile-likes {
  @apply tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-2;
}

.like-icon {
  @apply tw-bg-primary tw-rounded-full tw-p-2;
}

.expand-icon {
  bottom: 32px;
  left: calc(100% - 64px);
  bottom: 4.2%;
  left: calc(100% - 32px - 7.2%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;
}

.volume-icon {
  bottom: 32px;
  bottom: 4.2%;
  left: calc(100% - 72px - 7.2%);
  cursor: pointer;
}

.volume-icon,
.expand-icon {
  opacity: 0.8;
  transition: 0.3s;
}
.volume-icon:hover,
.expand-icon:hover {
  opacity: 1;
}

.expanded {
  .slide {
    transition: 0.3s;
    transition-property: opacity, padding;
    padding: 0;
    border-radius: 25px;
    overflow: hidden;
    opacity: 1;
    pointer-events: none;
    * {
      pointer-events: auto;
    }
  }
  .slide-next,
  .slide-prev {
    padding: 8vw 4vw;
    opacity: 0.5;
    transition: 0.3s !important;
  }
  .slide-invisible {
    opacity: 0;
    visibility: hidden;
    transition: 0s !important;
    padding: 8vw 4vw;
  }
}
</style>
