<template>
  <div class="slide">
    <div class="spinner" v-if="showSpinner">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="margin: auto; background: rgba(255, 255, 255, 0); display: block"
        width="60px"
        height="60px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <g transform="rotate(0 50 50)">
          <rect
            x="47"
            y="23"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#d5d5d5"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.9166666666666666s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(30 50 50)">
          <rect
            x="47"
            y="23"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#d5d5d5"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.8333333333333334s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(60 50 50)">
          <rect
            x="47"
            y="23"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#d5d5d5"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.75s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(90 50 50)">
          <rect
            x="47"
            y="23"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#d5d5d5"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.6666666666666666s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(120 50 50)">
          <rect
            x="47"
            y="23"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#d5d5d5"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.5833333333333334s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(150 50 50)">
          <rect
            x="47"
            y="23"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#d5d5d5"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.5s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(180 50 50)">
          <rect
            x="47"
            y="23"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#d5d5d5"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.4166666666666667s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(210 50 50)">
          <rect
            x="47"
            y="23"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#d5d5d5"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.3333333333333333s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(240 50 50)">
          <rect
            x="47"
            y="23"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#d5d5d5"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.25s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(270 50 50)">
          <rect
            x="47"
            y="23"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#d5d5d5"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.16666666666666666s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(300 50 50)">
          <rect
            x="47"
            y="23"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#d5d5d5"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.08333333333333333s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(330 50 50)">
          <rect
            x="47"
            y="23"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#d5d5d5"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="0s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
      </svg>
    </div>
    <video
      v-if="loaded || index == 0 || index == 1"
      playsinline
      ref="video"
      :src="videoSrc"
      preload
      class="video"
      @loadeddata="loadedData"
      @canplay="
        () => {
          canPlay = true;
        }
      "
      :muted="forceMuted || muted"
    >
      Your browser does not support the HTML5 Video element.
    </video>
  </div>
</template>

<script>
import { optimizeVideo } from '@/utils/optimizeVideo';

export default {
  name: 'VideoSlide',
  props: {
    item: Object,
    index: Number,
    forceMuted: Boolean,
    load: Boolean,
    visible: Boolean,
  },
  data() {
    return {
      muted: false,
      loaded: false,
      showSpinner: true,
      canPlay: false,
    };
  },

  methods: {
    show() {
      if (this.$refs.video) {
        this.$refs.video.pause();
        this.$refs.video.currentTime = 0;
        var playPromise = this.$refs.video.play();
        // In browsers that don’t yet support this functionality,
        // playPromise won’t be defined.
        if (playPromise !== undefined) {
          playPromise
            .then(function() {
              // Automatic playback started!
            })
            .catch(_ => {
              // Automatic playback failed.
              // Show a UI element to let the user manually start playback.

              this.muted = true;
              this.$nextTick(() => {
                this.$refs.video.play();
              });
            });
        }
      }
    },
    hide() {
      if (this.$refs.video) {
        this.$refs.video.pause();
      }
    },
    loadedData() {
      if (this.$refs.video) {
        this.$emit('duration-changed', this.$refs.video.duration);
      }
    },
  },
  computed: {
    videoSrc() {
      return optimizeVideo(this.item.url);
    },
    canPlayAndVisible: {
      get() {
        return this.canPlay && this.visible;
      },
    },
    carousel: {
      get() {
        return this.$parent.$parent.$parent;
      },
    },
  },
  mounted() {
    if (this.$refs.video && this.index == 0) {
      this.$refs.video.pause();
      this.$refs.video.currentTime = 0;
      var playPromise = this.$refs.video.play();
      // In browsers that don’t yet support this functionality,
      // playPromise won’t be defined.
      if (playPromise !== undefined) {
        playPromise
          .then(function() {
            // Automatic playback started!
          })
          .catch(_ => {
            // Automatic playback failed.
            // Show a UI element to let the user manually start playback.
            this.muted = true;
            this.$nextTick(() => {
              this.$refs.video.play();
            });
          });
      }
    }
  },
  watch: {
    load(val) {
      if (!this.loaded && val) {
        this.loaded = true;
        this.$nextTick(() => {
          if (this.$refs.video && this.index == 0) {
            this.$refs.video.pause();
            this.$refs.video.currentTime = 0;
            // var playPromise = this.$refs.video.play();
            // // In browsers that don’t yet support this functionality,
            // // playPromise won’t be defined.
            // if (playPromise !== undefined) {
            //   playPromise
            //     .then(function() {
            //       // Automatic playback started!
            //     })
            //     .catch(_ => {
            //       // Automatic playback failed.
            //       // Show a UI element to let the user manually start playback.
            //       this.muted = true;
            //       this.$nextTick(() => {
            //         this.$refs.video.play();
            //       });
            //     });
            // }
          }
        });
        // if (this.visible) {
        //   this.show;
        // }
      }
    },
    canPlayAndVisible(val) {
      if (val) {
        this.carousel.setCanPlay(true);
        this.showSpinner = false;
      }
    },
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          this.show();
        });
      } else {
        this.hide();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.slide {
  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.spinner {
  position: absolute;
  z-index: -1;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
}
</style>
