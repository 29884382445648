<template>
  <rl-carousel
    ref="carousel"
    v-model="slide"
    no-wrap
    @slide-changed="slideChanged"
  >
    <div
      slot-scope="{ wrapperStyles, slides: { count } }"
      class="insta-story"
      @click.stop="click"
      :class="{ 'tw-overflow-hidden': !expandStoryCarouselEnabled }"
    >
      <div
        :class="{ 'tw-overflow-hidden': !expandStoryCarouselEnabled }"
        class="tw-h-full tw-absolute tw-inset-0"
      >
        <div v-bind="wrapperStyles" class="w-100 h-100">
          <slot></slot>
        </div>
      </div>

      <div class="timeline">
        <div
          v-for="(item, index) in count"
          :key="index"
          class="slide-indicator"
          @click.stop="
            slide = index;
            clearInterval();
          "
        >
          <div class="progressbar w-100" v-if="slide > index">&nbsp;</div>
          <div
            class="progressbar partial"
            :style="{ 'animation-duration': getDuration(index) + 's' }"
            v-if="slide === index && canStart && active"
          >
            &nbsp;
          </div>
        </div>
      </div>

      <div v-if="!expandStoryCarouselEnabled" class="arrows">
        <div class="arrow left">
          <button @click.stop="prevSlide">
            <ArrowLeft />
          </button>
        </div>

        <div class="arrow right">
          <button @click.stop="nextSlide">
            <ArrowRight />
          </button>
        </div>
      </div>
    </div>
  </rl-carousel>
</template>

<script>
import ArrowLeft from '@/assets/inspirationPage/arrow_left.svg';
import ArrowRight from '@/assets/inspirationPage/arrow_right.svg';
import { RlCarousel } from 'vue-renderless-carousel';

export default {
  name: 'StoryCarousel',
  components: {
    ArrowLeft,
    ArrowRight,
    RlCarousel,
  },

  watch: {
    canStart(val) {
      if (val) {
        this.interval = setTimeout(() => {
          this.nextSlide();
        }, this.getDuration(this.slide) * 1000);
      }
    },
    active(val) {
      if (!val) {
        this.stopTimer();
      }
    },
  },
  computed: {
    width: {
      get() {
        return this.$refs.carousel.clientSize;
      },
    },
    canStart: {
      get() {
        return this.canStartTimer && this.canPlay && this.active;
      },
    },
  },
  props: {
    expandStoryCarouselEnabled: Boolean,
    isMobile: Boolean,
    active: Boolean,
    defaultDuration: {
      type: Number,
      default: 10,
    },
    durations: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      slide: 0,
      slideCount: 0,
      interval: undefined,
      canStartTimer: false,
      canPlay: false,
    };
  },
  methods: {
    setCanPlay(val) {
      this.canPlay = val;
    },
    resetSlide() {
      this.stopTimer();
      this.slide = 0;
      this.startTimer();
    },
    stopTimer() {
      if (this.interval) {
        clearTimeout(this.interval);
      }
    },
    startTimer() {
      this.canStartTimer = true;
    },
    click(e) {
      this.$emit('click', e);
      if (!this.expandStoryCarouselEnabled && !this.isMobile) {
        if (e.offsetX < this.width / 2) {
          this.prevSlide();
        } else {
          this.nextSlide();
        }
      }
    },
    nextSlide() {
      this.stopTimer();
      if (this.slide === this.$refs.carousel.slideCount - 1) {
        this.slide = 0;
      } else {
        this.slide++;
      }
      this.startTimer();
    },
    prevSlide() {
      this.stopTimer();
      if (this.slide === 0) {
        this.slide = this.$refs.carousel.slideCount - 1;
      } else {
        this.slide--;
      }
      this.startTimer();
    },
    getDuration(index) {
      if (this.durations[index]) {
        return this.durations[index];
      }
      return this.defaultDuration;
    },
    slideChanged(index) {
      this.canPlay = false;
      this.$emit('slideChanged', index);
    },
  },
  created() {
    this.startTimer();
  },
  beforeDestroy() {
    this.stopTimer();
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
@import '~@/assets/styles/app.scss';
@import '~@/assets/styles/variables.scss';
.insta-story {
  @apply tw-relative tw-text-white;
  padding-bottom: calc(100% * 760 / 440);
  border-radius: 25px;
  @include respond-to(xs) {
    border-radius: unset !important;
  }

  .timeline {
    @apply tw-absolute tw-flex tw-top-0 tw-w-full tw-p-2 tw-mt-2 tw-ml-2 tw-pr-4;
  }

  .slide-indicator {
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 2px black;
    margin: 3px;
    height: 3px;
    flex-grow: 1;
    border-radius: 2px;
    overflow: hidden;
    cursor: pointer;
  }

  .progressbar {
    background-color: rgba(255, 255, 255, 1);

    @keyframes progressbar {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }

    &.partial {
      width: 0;
      animation: progressbar 2s linear infinite;
    }
  }

  .arrows {
    @apply tw-absolute tw-top-0 tw-bottom-0 tw-right-0 tw-left-0 tw-flex tw-justify-between tw-items-center tw-p-2;

    svg {
      @apply tw-opacity-60;

      &:hover {
        @apply tw-opacity-100;
      }
    }
  }
}
@media (max-width: 760px) {
  .insta-story {
    max-width: calc(100% - 52px);
  }
}

.insta-story {
  @include respond-to(xs) {
    max-width: unset !important;
  }
}
</style>
