var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MatchMedia',{scopedSlots:_vm._u([{key:"default",fn:function({ md }){return [_c('div',{staticClass:"tw-relative",class:{ expanded: _vm.expandStoryCarouselEnabled },on:{"mouseenter":function($event){_vm.likeComponentVisible = true},"mouseleave":function($event){_vm.likeComponentVisible = true}}},[_c('StoryCarousel',{ref:"carousel",staticClass:"story",attrs:{"default-duration":5,"active":_vm.active,"isMobile":_vm.isMobile,"durations":_vm.durations,"expandStoryCarouselEnabled":_vm.expandStoryCarouselEnabled},on:{"slideChanged":_vm.slideChanged,"click":e => _vm.$emit('click', e)}},[_vm._l((_vm.items),function(item,i){return [_c('rl-carousel-slide',{key:item.url,staticClass:"slide w-100 h-100 position-relative tw--top-10",class:{
            'slide-invisible':
              _vm.slide !== i && _vm.slide !== i + 1 && _vm.slide !== i - 1,
            'slide-next': _vm.slide === i + 1,
            'slide-prev': _vm.slide === i - 1,
          }},[(_vm.slide === i + 1 || _vm.slide === i - 1 || _vm.slide === i)?[_c('div',{staticClass:"tw-top-20 tw-left-5 tw-relative tw-z-10"},[(_vm.isMobile)?_c('MobileUserIcon',{attrs:{"bgImage":_vm.influencer.image,"userName":_vm.influencer.influencer_id,"avaBorderColor":"primary","checked":""}}):_vm._e(),(!_vm.isMobile)?_c('user-icon',{attrs:{"bgImage":_vm.influencer.image,"userName":_vm.influencer.influencer_id,"avaBorderColor":"primary","checked":""}}):_vm._e()],1)]:_vm._e(),(
              item.type === 'image' &&
              ((_vm.slide === i + 1 && _vm.active) ||
                (_vm.slide === i - 1 && _vm.active) ||
                _vm.slide === i)
            )?_c('ImageSlide',{ref:"slides",refInFor:true,attrs:{"visible":_vm.slide === i,"item":item,"index":i},on:{"click":event => {
                if (_vm.expandStoryCarouselEnabled) {
                  if (_vm.slide === i + 1) {
                    _vm.$refs.carousel.nextSlide();
                  }
                  if (_vm.slide === i - 1) {
                    _vm.$refs.carousel.prevSlide();
                  }
                }
              }}}):_vm._e(),(item.type === 'video')?_c('VideoSlide',{ref:"slides",refInFor:true,attrs:{"item":item,"index":i,"load":(_vm.slide === i + 1 && _vm.active) ||
              (_vm.slide === i - 1 && _vm.active) ||
              _vm.slide === i,"visible":_vm.slide === i,"forceMuted":_vm.forceMuted},on:{"duration-changed":d => _vm.durationChanged(i, d),"click":event => {
                if (_vm.expandStoryCarouselEnabled) {
                  if (_vm.slide === i + 1) {
                    _vm.$refs.carousel.nextSlide();
                  }
                  if (_vm.slide === i - 1) {
                    _vm.$refs.carousel.prevSlide();
                  }
                }
              }}}):_vm._e()],2)]})],2),(!md && !_vm.isMobile)?[(_vm.storyId && (_vm.likeComponentVisible || _vm.isLiked))?_c('div',{staticClass:"like-component"},[_c('a',{staticClass:"mobile-likes",on:{"click":_vm.toggleLike}},[(!_vm.isLiked)?_c('div',{staticClass:"like-icon"},[_c('LikeIcon',{attrs:{"width":"20"}})],1):_vm._e(),(_vm.isLiked)?_c('div',{staticClass:"like-icon"},[_c('LikeFilledIcon',{attrs:{"width":"20"}})],1):_vm._e(),_vm._v(" "+_vm._s(_vm.likeCount)+" likes ")])]):_vm._e()]:[(_vm.showButtons)?_c('div',{staticClass:"volume-icon tw-bg-white tw-rounded-full tw-h-8 tw-w-8 tw-absolute",on:{"click":event => {
            event.stopPropagation();
            _vm.forceMuted = !_vm.forceMuted;
          }}},[(_vm.forceMuted)?_c('MuteIcon'):_c('UnmuteIcon')],1):_vm._e(),(_vm.showButtons)?_c('div',{staticClass:"expand-icon tw-bg-white tw-rounded-full tw-h-8 tw-w-8 tw-pt-2 tw-pb-2 tw-absolute",on:{"click":event => {
            event.stopPropagation();
            _vm.$emit('toggle-expand');
          }}},[_c('ExpandIcon')],1):_vm._e()]],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }