<template>
  <div class="slide" :style="{ 'background-image': `url(${src})` }" />
</template>

<script>
import { transformImage } from '@/utils/transformImage';

export default {
  name: 'ImageSlide',
  props: {
    item: Object,
    visible: Boolean,
    index: Number,
  },
  mounted() {
    if (this.index == 0) {
      this.carousel.setCanPlay(true);
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.carousel.setCanPlay(true);
      }
    },
  },
  computed: {
    src() {
      return transformImage(this.item.url);
    },
    carousel: {
      get() {
        return this.$parent.$parent.$parent;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.slide {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
